<template>
  <div class="max-w-screen-xl mx-auto w-full p-2 rounded-lg">
    <div class="w-full flex flex-col lg:flex-row flex flex-row justify-between">
        <div class="flex flex-col p-1">
          <div class="text-4xl uppercase font-bold">Are you a game shop owner?</div>
          <div>Find some unique small-run gaming items to add a bit of extra kick to your shelves!</div>
          <div class="my-5">Send a message to <a class="text-red-500" href="mailto:skellypacks@gmail.com" target="_blank">SkellyPacks@gmail.com</a> if you're interested in stocking any of these items.</div>
          <div class="w-full flex flex-col gap-y-10 md:gap-y-5">
            <Project v-for="item in [
              { id: 'deka', 
                name: 'DEKA-DICE D20 Deck', 
                desc: 'A 72 card deck that works just like a D20 set for your favorite tabletop games!<br /><br />You can also give your game a new strategy twist and play in <b>Discard Mode</b> or with the optional deck <b>Power-Roll</b>.', 
                kickstarterDates: 'October 1st - November 1st', 
                kickstarter: 'https://www.kickstarter.com/projects/skellypacks/deka-dice',
                img: 'https://skelly.cards/deka02.png'
              },
              { id: 'powerroll', 
                name: 'DEKA-DICE Power-Roll', 
                desc: 'An optional 35 card deck that adds a new layer of risk and reward to every roll of a die!<br /><br />Designed to be used by a tabletop group playing with <b>DEKA-DICE</b> decks, only one <b>Power-Roll</b> deck is needed for an entire table.', 
                kickstarterDates: 'October 1st - November 1st', 
                kickstarter: 'https://www.kickstarter.com/projects/skellypacks/deka-dice',
                img: 'https://skelly.cards/powerroll.png'
              },
              { id: 'gbdeck', name: 'GB Deck Playing Cards', desc: 'A standard deck of playing cards inspired by the original handheld Gaming Brick: the <b>GB Deck</b>!<br /><br />The card back design features an explosion of handheld parts and face cards are inspired by some of the top hits for the system.', kickstarter: 'https://www.kickstarter.com/projects/blueg/pocket-power-playing-card-deck-x-handheld-gaming-legend', stock: 'Available', img: 'https://skelly.cards/gbdeck.png' }
              
            ]" 
              :key="item.id"
              :name="item.name"
              :desc="item.desc"
              :kickstarterDates="item.kickstarterDates"
              :kickstarter="item.kickstarter"
              :img="item.img" />
          </div>
           </div>
      <div class="flex-shrink-0">
        <iframe width="540" height="600"
          src="https://9dddd217.sibforms.com/serve/MUIFAFbkYbSv-lo3HmFejpTdkx0gQjNLoZ8nt8LSAuhuTCKxMZS9_VuaHUuUMcE3WcX7QSx6sD9Vgr0Un1bf4rmeLoIR07NEcwQviyM5JZ4CMtDzgTYf783bqxnZIkZVmfza3RUm1xro3lJlGN7zx18yeAAGLpMgtzGDuBx8Hf_WmskuzYeJWGJt6vyp1gQdjISWnFjMMdYdi78N" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
        </div>
    </div>
  </div>
</template>


<script>

import Project from '@/components/Project.vue'

export default {
  name: 'HomeView',
  components: {
    Project
  },
  methods: {
    openKickstarter(){
      window.open('https://www.kickstarter.com/projects/skellypacks/deka-dice')
    }

  }
}
</script>


