<template>
<div class="w-full flex flex-col">
    <div class="w-full rounded-sm p-2 flex flex-col md:flex-row">
      <div class="block md:hidden text-xl font-bold text-center md:text-left w-full">{{name}}</div>
      <div class="w-4/5 mx-auto md:w-64 md:self-stretch bg-black flex-shrink-0 flex flex-col justify-center md:mr-4 rounded">
        <img :src="img" class="w-full rounded" />
      </div>     
      <div class="flex flex-col pl-2 gap-y-1 flex-grow"> 
        <div class="flex flex-row items-center w-full justify-between">
          <div class="hidden md:block text-xl font-bold text-center md:text-left w-full">{{name}}</div>
        </div>
        <a v-if="steam" :href="steam" target="_blank" class="text-center text-black bg-yellow-400 hover:bg-yellow-500 duration-150 p-1 font-bold text-sm rounded-sm">View on Steam</a>
        <a v-if="youtube" :href="youtube" target="_blank" class="text-center text-white bg-red-600 hover:bg-red-500 duration-150 p-1 font-bold text-sm rounded-sm">View on YouTube</a>        
        <a v-if="kickstarterDates" :href="kickstarter" target="_blank" class="text-center bg-green-600 hover:bg-green-500 duration-150 p-1 font-bold text-sm rounded-sm">Kickstarter Runs {{kickstarterDates}}!</a>
        <a v-else-if="kickstarter" :href="kickstarter" target="_blank" class="text-center bg-gray-700 hover:bg-gray-600 duration-150 p-1 font-bold text-sm rounded-sm">View Kickstarter Campaign</a>
        <div class="leading-5 opacity-80" v-html="desc" />
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    img: String,
    name: String,
    kickstarter: String,
    kickstarterDates: String,
    desc: String,
    youtube: String,
    steam: String
  }
}
</script>
