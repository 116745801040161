import { createRouter, createWebHistory } from 'vue-router'
//import ContestView from '../views/HomeView.vue'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ShopsView from '../views/ShopsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/shops',
    name: 'shops',
    component: ShopsView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/:pathMatch(.*)*', // Updated catch-all route
    name: 'NotFound',
    component: HomeView // Redirect to 404 page
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
